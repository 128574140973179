import * as firebase from "firebase";
import "firebase/database";

let config = {
    apiKey: "AIzaSyDgH0-h9Y8dgxNfODjxtd9Y7cS4ZSoaHoU",
    authDomain: "djelmenor-1457c.firebaseapp.com",
    databaseURL: "https://djelmenor-1457c-default-rtdb.firebaseio.com",
    projectId: "djelmenor-1457c",
    storageBucket: "djelmenor-1457c.appspot.com",
    messagingSenderId: "349563195547",
    appId: "1:349563195547:web:33074a4d27faeace9b363e",
    measurementId: "G-S24TR6C5CF"
};

firebase.initializeApp(config);

export default firebase.database();